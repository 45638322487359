<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-table style="margin-top: 10px;" :dataSource="list" :pagination="false" :columns="columns" rowKey="id"
				size="middle">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key == 'action'">
						<a-dropdown>
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
							<template #overlay>
								<a-menu>
									<div @click="toSee(record)">
										<a-menu-item>
											<a>查看</a>
										</a-menu-item>
									</div>
									<div v-permission="['performance_manage_service_edit']" @click="toEdit(record)">
										<a-menu-item>
											<a>编辑</a>
										</a-menu-item>
									</div>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
	<temp v-if="showModal" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import {
		Icon
	} from "@/components/icon/icon.js";
	import temp from './temp.vue';
	import {
		getPerformTipsList
	} from "@/service/modules/perform";
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				loading: false,
				showModal: false,
				isSee: false,
				list: [],
				columns: [{
					title: '服务标题',
					dataIndex: 'title'
				}, {
					title: '操作',
					key: 'action',
					width: 500,
				}],
				id: 0,
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			toEdit(item) {
				this.id = item.id;
				this.isSee = false;
				this.showModal = true;
			},
			toSee(item) {
				this.id = item.id;
				this.isSee = true;
				this.showModal = true;
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPerformTipsList({
						type: 2,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
		}
	}
</script>

<style>
</style>