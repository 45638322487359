<template>
	<div>
		<Header :title="isSee ? '查看服务' : '编辑服务'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
				@finish="onSubmit" :labelCol="{span: 4}" :wrapperCol="{span: 14}">
				<a-form-item label="服务标题">
					{{ modelRef.title }}
				</a-form-item>

				<a-form-item name="description" :rules="[{ required: true, message: '必填项不允许为空' }]" label="描述内容">
					<div style="position: relative;z-index: 99;">
						<a-textarea v-model:value="modelRef.description" placeholder="请输入描述内容"></a-textarea>
						<!-- <Editor v-model:value="modelRef.description" :configSetting="{ disabled: isSee }"></Editor> -->
					</div>
				</a-form-item>

				<a-row>
					<a-col :offset="6">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import Editor from '@/components/editor/editor';
	import {
		getPerformTipsDetail,
		updatePerformTips
	} from "@/service/modules/perform";
	export default {
		components: {
			Header,
			Editor
		},
		props: {
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				modelRef: {}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPerformTipsDetail({
						id: this.id,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.modelRef = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.type = 2;
				this.loading = true;
				try {
					postData.id = this.id;
					let ret = await updatePerformTips(postData);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch (e) {
					this.loading = false;
				}
			}
		}
	}
</script>

<style>
</style>